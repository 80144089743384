import React, {useContext,useEffect,useState} from 'react'
import {Box ,
        Typography,
        Accordion,AccordionDetails,AccordionSummary,makeStyles ,
 } from "@material-ui/core";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DOMPurify from 'dompurify';
import { formularioContext } from '../contextos/FormularioContext'

export const PreguntasFrecuentes = ({id})=>{
    const {buscarPreguntasFrecuentes} = useContext(formularioContext)
    const [preguntas,setPreguntas]= useState(null)

  useEffect(()=>{
    if(id){
      buscarPreguntasFrecuentes(id)
      .then(preguntasweb=>{
          setPreguntas(preguntasweb)
      })
    }
  },[id])

if (!preguntas) return null

return  <Box sx={{padding:'16px'}}> 
    <Typography style={{padding:'20px'}} variant='h6' align='left' color='primary'>
     Preguntas frecuentes
 </Typography>
   {preguntas.map((item,index)=>{
       return  <SeccionPregunta
       pregunta={item.pregunta}
       respuesta={item.respuesta}
       id= {`pregunta-${index}`}
   />
   })}
</Box>
}

const SeccionPregunta = ({pregunta, respuesta,id})=>{

    return  <Accordion>
    <AccordionSummary sx={{padding:'50px'}}
      expandIcon={<ArrowDownwardIcon />}
      aria-controls={id}
      id={id}
    >
      <Typography variant='h6'>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pregunta) }} />
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography align='left'  variant='subtitle1'>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(respuesta) }} />
      </Typography>
    </AccordionDetails>
  </Accordion>
}