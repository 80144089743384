import React from 'react'
import Logo from './Logo'

export const Default = ({texto})=>{
    return <div style={{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        height:'80vh'
      }}>
          <Logo/>
          <h1>{texto || `Página inexistente`}</h1>
        </div>
}