import React, {useContext,useEffect,useState} from 'react'
import {Button, TextField, Box ,Typography,Paper,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { formularioContext } from '../contextos/FormularioContext'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/EditOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {Sexo} from './Sexo'
import {Alert} from '@mui/material'
import {useHelper} from '../hooks/useHelper'

const regex_solo_numeros = /^[0-9\b]+$/;

export const GrupoPersonas = ()=>{
    const {errorMail,datos,grupo,updateContingente_o_grupo,eventoValorSeleccionado} = useContext(formularioContext)
    const [agregar,setAgregar]=useState(false)
    const {hacerScroll} = useHelper()
    const cant_personas_extras = Number(datos.cant_max_personas) > 1 ? Number(datos.cant_max_personas) - 1 : 0;

    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
    
    <TableContainer component={Paper}>
      <Table id="contingente" aria-label="simple table">
      {grupo.length > 0 && <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell align="left">DNI</TableCell>
            <TableCell align="left">Edad</TableCell>
            <TableCell align="left">Sexo</TableCell>
          </TableRow>
        </TableHead>}
        <TableBody>
          {/*<PrimeraLinea datos={datos} infoMinistro={infoMinistro} contingente={contingente} descripcionProvincia={descripcionProvincia}/>*/}
          {grupo.map((row,index) => (
            <>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell align="left"><Typography variant="h6">{index+1}</Typography>
              <Paper elevation={3} />
              </TableCell>
              <TableCell align="left"><IconButton onClick={()=>updateContingente_o_grupo(row,true,true)}><DeleteIcon /></IconButton></TableCell>
              <TableCell align="left">{row?.nombreCompleto ? row?.nombreCompleto : row.nombre}</TableCell>
              <TableCell align="left">{row?.nro_documento}</TableCell>
              <TableCell align="left">{row?.edad}</TableCell>
              <TableCell align="left">{row?.sexo}</TableCell>
            </TableRow>
            </>
          ))}
          {!agregar && grupo.length > 0 && <TableRow>
                <TableCell colSpan="6" align="left">
                    <Typography align='right'>{grupo.length} participantes de {Number(cant_personas_extras)}</Typography>    
                </TableCell>
          </TableRow>}
          {!agregar && Number(cant_personas_extras) > Number(grupo.length) && <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer'}}
            >

                <TableCell colSpan="5" align="left"><Button onClick={()=>setAgregar(true)} variant='outlined' color='secondary'>Agregar {grupo.length==0 ? 'una' : 'otra'} persona +</Button></TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
    {agregar && <Nuevo hacerScroll = {hacerScroll} 
                solicitante={datos} 
                cantidad_permitida={cant_personas_extras} 
                contingente={grupo} 
                update={updateContingente_o_grupo} 
                cancelar={()=>setAgregar(false)} errorMail={errorMail}
                eventoValorSeleccionado = {eventoValorSeleccionado}
                />}

  </Box>   
}

const Nuevo = ({update,cancelar,contingente,cantidad_permitida,hacerScroll,eventoValorSeleccionado})=>{
    
    const [datos,setDatos] =  useState({
        nombre:'',
        apellido:'',
        nro_documento:'',
        edad:'',
        sexo:'M',
        nombreCompleto:''
    })

    useEffect(()=>{
        hacerScroll('newperson-1')
    },[])

    useEffect(()=>{
        setDatos({...datos,nombreCompleto:`${datos.apellido}, ${datos.nombre}`})
    },[datos.nombre,datos.apellido])

    const handleChangeNombre = (e)=>{
        setDatos({...datos,nombre:e.target.value.trim().toUpperCase()})
    }

    const handleChangeApellido = (e)=>{
        setDatos({...datos,apellido:e.target.value.trim().toUpperCase()})
    }

    const handleChangeDni = (e)=>{
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setDatos({...datos,nro_documento:e.target.value.trim()});
        }
    }

    const handleChangeEdad = (e)=>{
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setDatos({...datos,edad:e.target.value.trim()});
        }
    }

    const handleChangeSexo = (e) => {
        setDatos({...datos,sexo:e.target.value});
    };
   
    const iniciarGrabar = ()=>{
        if(datosMinistroOk()[0]){
            update(datos,false,true)
            .then((respuesta)=>{
                if(respuesta){
                    cancelar()
                }
            })
        }else{
            alert(datosMinistroOk()[1])
        }

    }

    const datosMinistroOk = ()=>{

            if(datos.nombre.trim()==''){
                return [false,`Falta completar el nombre`]
            }
    
            if(datos.apellido.trim()==''){
                return [false,`Falta completar el apellido`]
            }
    
            if(!datos.sexo || datos.sexo.trim()==''){
                return [false,`Falta completar el género`]
            }

            if(datos.nro_documento.trim()==''){
                return [false,`Falta completar el número de documento`]
            }

            if(datos.nro_documento.trim().length>9){
                return [false,`El número de documento es demasiado largo`]
            }

            if(datos.nro_documento.trim().length<7){
                return [false,`El número de documento requiere al menos 7 dígitos`]
            }
            
            if(!datos.edad || Number(datos.edad)==0){
                return [false,`Falta completar la edad`]
            }

            //const edad2 = Number(datos.edad) 


            if(eventoValorSeleccionado){
                if(eventoValorSeleccionado?.rango_edad){
                    const rangoEdad = eventoValorSeleccionado?.rango_edad.split(',');
                    const controlEdad = Number(datos.edad) 
                    if(controlEdad<Number(rangoEdad[0]) || controlEdad>Number(rangoEdad[1])){
                        return [false,`El tipo de inscripción seleccionado requiere un rango de edad entre ${rangoEdad[0]} y ${rangoEdad[1]}`]
                    }
                }else{
                    return [false,`No se ha especificado el rango de edad para la inscripción seleccionada. Por favor comuníquese con la ofina UAD`]
                }
            }else{
                return [false,`No se identificó el tipo de inscripción seleccionado`]
            }

        return [true,null]
    }

    return <Box sx={{border:'solid 1px gray', borderRadius:'10px',padding:'2px'}}>
        Participante {contingente.length+1} de {cantidad_permitida} 
    <Typography id="newperson-1" style={{ fontWeight: 600,marginTop:'1rem',background:'#3f51b5',color:'white',paddingLeft:'0.5rem' }} variant="caption" display="block">
                                Ingrese una nueva persona:
                    </Typography>
    <TableContainer>
    <Table>
    <TableBody>
    <TableRow title='Solicitante'
                sx={{ background:''}}
                >
                    <TableCell sx={{verticalAlign:'top'}} colSpan="6" align="left">
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{display:'flex'}}>
                                <TextField 
                                style={{fontSize:'small'}}
                                fullWidth inputProps={{maxLength: 25}} id="dp-nombre" autoFocus 
                                value={datos.nombre}
                                onChange={handleChangeNombre} label="Nombre" variant="filled" />

                                <TextField fullWidth inputProps={{maxLength: 25}} id="dp-apellido"  
                                value={datos.apellido} 
                                onChange={handleChangeApellido} label="Apellido" variant="filled" />
                                
                                <TextField inputProps={{maxLength: 9}} id="dp-dni"  
                                value={datos.nro_documento} 
                                fullWidth
                                onChange={handleChangeDni} label="DNI" variant="filled" />
                            </Box>
                           
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <TextField inputProps={{maxLength: 2}} id="dp-edad"  
                                value={datos.edad} 
                                onChange={handleChangeEdad} label="Edad" variant="filled" />
                                <Box sx={{marginLeft:'3rem'}}>
                                    <Sexo sexo={datos.sexo} handleChangeSexo={handleChangeSexo}/>
                                </Box>
                        </Box>
                    </TableCell>
        </TableRow>
        <TableRow colSpan="4">
            <Box sx={{display:'flex',justifyContent:'end'}}>
            {!datosMinistroOk()[0] && <Alert  severity={datosMinistroOk()[0]? 'info' : 'error'}>
                        {datosMinistroOk()[1]}
            </Alert>}
                    <Button style={{margin:'1rem'}} variant='contained' color='primary' onClick={iniciarGrabar}>Agregar</Button>
                    <Button variant='outlined' onClick={cancelar}>Cancelar</Button>

            </Box>
        </TableRow>
       
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
}

