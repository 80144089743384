import React, { useEffect, useContext} from 'react'
import {Box,Button,CardActions,Typography,CardContent,Card,CardHeader,Grid,List,ListItem,ListItemIcon,ListItemText} from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import {useHelper} from '../hooks/useHelper'
import { NuevaInscripcionNew } from './NuevaInscripcionNew';
import {v4 as uuidv4} from 'uuid';
import { formularioContext } from '../contextos/FormularioContext'

export const Precios = ({id_evento,setEnSeleccion,evento})=>{
    const [productos,setProductos] = React.useState([])
    const [valor,setValor] = React.useState(null)
    const {formatearMontoMoneda}= useHelper()
    const [clickReady,setClickReady] = React.useState(false)
    const [idSeleccionado,setIdSeleccionado] = React.useState(null)
    const {updateEventoValorSeleccionado} = useContext(formularioContext)

    const buscarEventosValor = async (id_evento,setEnSeleccion)=>{
        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${id_evento}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${id_evento}`)
            setProductos(data)
            setValor(data[0]?.id_evento_valor?.toString())
        }catch(err){
            alert(err)
        }
    }

    useEffect(()=>{
        buscarEventosValor(id_evento)
    },[])

    useEffect(()=>{
       updateEventoValorSeleccionado(idSeleccionado)
       setEnSeleccion(idSeleccionado ? true : false)
    },idSeleccionado)

    return <div>
    <Typography style={{margin:'1.5rem',marginBottom:'1rem',textAlign:'center'}} variant="h4"> {idSeleccionado ? 'Inscripción seleccionada:':'Tipos de inscripción:'}</Typography>
      
    {idSeleccionado && <div>
        <DetalleProducto evento={evento} item={idSeleccionado} setIdSeleccionado={setIdSeleccionado} formatearMontoMoneda={formatearMontoMoneda}/>
        <NuevaInscripcionNew id_evento={id_evento} eventoSeleccionado={idSeleccionado}/>
    </div>}
    {!idSeleccionado && <Grid container spacing={2} justifyContent="center">
    {productos.filter(producto=>producto.activo).map((item, index) => (
        <DetalleProducto listIndex={index+1} item={item} setIdSeleccionado={setIdSeleccionado} formatearMontoMoneda={formatearMontoMoneda}/>
    ))}
  </Grid>}
  </div>
}

const DetalleProducto = ({item,formatearMontoMoneda,setIdSeleccionado,listIndex,evento})=>{
  const index = uuidv4();

  return <Grid item key={index} xs={12} md={6} >
  <Card  style={{ height: '100%', display: 'flex', flexDirection: 'column',background:'#c3c3cf' }}>
    <Typography style={{margin:'1.5rem',marginBottom:'1rem',textAlign:'center'}} variant="h5"> {evento? evento.titulo : null}</Typography>
    <CardHeader
      id={`item-detalle-${item.id_evento_valor}`}
      title={item.info_adicional}
      titleTypographyProps={{ align: 'center' }}
    />
    <CardContent>
      <Typography variant="body1" align="center">
        {item.detalle}
      </Typography>
      <Typography variant="h6" align="center" color="primary">
          $ {formatearMontoMoneda(item.valor_mp)}
      </Typography>
      <List>
          {item?.texto1 && <ListItem key={`${index}t1`}>
                  <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item?.texto1} />
          </ListItem>}
          {item?.texto2 && <ListItem key={`${index}t2`}>
                  <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item?.texto2} />
          </ListItem>}
          {item?.texto3 && <ListItem key={`${index}t3`}>
                  <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item?.texto3} />
          </ListItem>}
          {item?.texto4 && <ListItem key={`${index}t4`}>
                  <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item?.texto4} />
          </ListItem>}                
      </List>
    </CardContent>
    <Button onClick={()=>setIdSeleccionado(listIndex ? item : null)} variant='contained'  color='primary'>{listIndex ? 'Seleccionar' : 'Cancelar'}</Button>
  </Card>
</Grid>
}