import './App.css';
import {Formulario} from './componentes/Formulario'
import {FormularioContextProvider} from './contextos/FormularioContext'
import theme from './themeConfig'
import {ThemeProvider} from '@material-ui/core'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState } from 'react';
import {FueraDeServicio} from './componentes/FueraDeServicio'
import {Opciones} from './componentes/Opciones'
import {Constancia} from './componentes/Constancia'
import {Colaborador} from './componentes/Colaborador'
import {Eventos} from './componentes/Eventos'
import { Default } from './componentes/Default';

function App() {
  return (
    <Router>
      <div className="App" stylxe={{height:'100%',backgroundRepeat:'no-repeat',backgroundSize:'cover',
      background:'rgb(26, 36, 67)',
     }}>
        {/*<div style={{backgroundColor:'rgb(25, 118, 210)',color:'white'}}>*/}
        <div>
          {/*Para que funcione correctamente en desarrollo debe correr en el localhost 3000 y el servidor de desarrollo debe estar operativo dado que el CORS esta configurado para que acepte peticiones del localhost 3000 o de la ip de producción
          */}
          {/* {<Logo/>} */}
          <ThemeProvider theme={theme} >
              <FormularioContextProvider>
                 {/*<FueraDeServicio/>*/}
                  {/*<Formulario/>*/}
                 <Routes>
                      <Route default path='*' element={<Default/>} />  
                      <Route default path='/' element={<Default texto="Sitio en mantenimiento"/>} />   
                      {/* <Route default path='/' element={<Formulario tipo="general"/>} />    */}
                      {/* <Route default path='/' element={<Eventos/>} />   */}
                      {/* <Route path='/contingentes/inscripcion/:nro_cupon' element={<Formulario tipo="contingente"/>} />  
                      <Route path='/cupones/inscripcion/constancia/:id_alta_web' element={<Constancia tipo="contingente"/>} />  
                      <Route path='/contingentes/inscripcion/' element={<Formulario tipo="contingente"/>} />   */}
                      <Route path='/colaborador/:id/:id_evento' element={<Colaborador/>} />  
                      <Route path='/admin/temporal/:id/:id_evento' element={<Colaborador noValidarUsuario={true}/>} />  
                      {/* <Route path='/preferencia' element={<Opciones tipoInscripcion="preferencia"/>} />   */}
                      {/* <Route path='/eventos' element={<Eventos />} />  */}
                      <Route path='/eventos' element={<Default texto="Sitio en mantenimiento"/>} />   
                      <Route path='/temporal' element={<Eventos/>} />   
                      <Route path='/testing/:token/' element={<Eventos/>} />  
                </Routes>
               {/*<MesaDeAyuda/>*/}
              </FormularioContextProvider>
          </ThemeProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;

// const Default = ({texto})=>{
//   return <div style={{
//     display:'flex',
//     justifyContent:'center',
//     flexDirection:'column',
//     alignItems:'center',
//     textAlign:'center',
//     height:'80vh'
//   }}>
//       <Logo/>
//       <h1>{texto || `Página inexistente`}</h1>
//     </div>
// }

