import React, { useEffect, useState} from 'react'
import {Box,Button,CardActions,Typography,CardContent,Card,CardHeader,CardMedia,Grid} from "@material-ui/core";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HomeIcon from '@mui/icons-material/Home';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Ingreso} from './Ingreso'
import {Loader} from './Loader'
import CryptoJS from 'crypto-js'
import { Margin, Padding } from '@mui/icons-material';
import {Dialogos} from './Dialogos'
import { Precios } from './Precios';
import {useHelper} from '../hooks/useHelper';
import Stack from '@mui/material/Stack';
import { PreguntasFrecuentes } from './PreguntasFrecuentes';
import { Default } from './Default';
import { NuevaInscripcion } from './NuevaInscripcion';
import { NuevaInscripcionNew } from './NuevaInscripcionNew';
export const Eventos = ()=>{
    const [eventos,setEventos] = useState([])
    const [tienePermiso,setTienePermiso] = useState(null)
    const [codigoEsValido,setCodigoEsValido] = useState(true)
    const [esModoTest,setEsModoTest] = useState(false)
    const [cargando,setCargando]= useState(true)
    const parametros = useParams()
    const [idEvento,setIdEvento] = useState(null)
    const [eventoSeleccionado,setEventoSeleccionado]=useState(null)
    const [abrirPreguntas,setAbrirPreguntas]=useState(null)
    const {hacerScroll} = useHelper()
    const [enSeleccion,setEnSeleccion]=useState(false)

    useEffect(()=>{
        const obtenerEventos = async ()=>{
            try{
                const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/documentos/events`:`https://uadapi.uad.digital/documentos/events`)
                setEventos(data) 
                setCargando(false)
              
            }catch(err){
                console.log('Error loading events',err)
                setCargando(false)
            }
           
        }

        const obtenerEventoParaTesting = async ()=>{
            try{
                const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/documentos/validartokentest/${parametros?.token}`:`https://uadapi.uad.digital/documentos/validartokentest/${parametros?.token}`)
                if(data[0]?.id_evento){
                    setEventos(data) 
                    setEsModoTest(true)
                }
                setCargando(false)
            }catch(err){
                setEventos([])
                setEsModoTest(false)
                setCargando(false)
                console.log('Error al acceder con un token de test',err, JSON.stringify(err?.response) )
                if(err?.response?.data?.message=="jwt expired")
                    {
                        alert('El token del evento ha expirado. Luego de ser generado desde administración tiene una duración de 1 hora')
                    }else{
                        alert('El token del evento no es válido o ha expirado')
                    }
            }
           
        }

        if(parametros?.token){
            obtenerEventoParaTesting()
        }else{
            obtenerEventos()
        }

    },[])

    useEffect(()=>{
        if(idEvento){
            const evento = eventos.filter(i=>i.id_evento===idEvento)[0];
            setEventoSeleccionado(evento);
            setTimeout(() => {
                hacerScroll("evento-inicio")
            }, 200);
        }else{
            setEventoSeleccionado(null);
        }
    },[idEvento])

    const cancelarEvento = ()=>{
        setIdEvento(null)
    }

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    if(eventos.filter((i)=>i?.activo || esModoTest).length==0)
    {
        return <Default texto={"No hay eventos activos en este momento"}/>
    }

    return <div>
    <Dialogos open={abrirPreguntas} 
            textoCancelar={'Volver'}
            // fullscreen={true}
            procesarCancelar = {()=>setAbrirPreguntas(null)}
            >
            <Typography variant="h6" style={{marginTop:'5px'}} align="center">
                                    {eventoSeleccionado?.titulo}
            </Typography>
            <PreguntasFrecuentes id={eventoSeleccionado?.id_preguntas_frecuentes}/>
    </Dialogos>
    <Dialogos open={eventoSeleccionado} 
                    textoCancelar={'Volver'}
                    fullscreen={true}
                    procesarCancelar = {cancelarEvento}
                   >

                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>

                    <Button id="evento-inicio" onClick={cancelarEvento} style={{width:'100px'}} variant="outlined" startIcon={<HomeIcon />}>
                        Inicio
                    </Button>
                     {eventoSeleccionado && 
                     <>
                        <Grid container spacing={2} justifyContent="center"
                        style={{backgroundImage52:`url("${eventoSeleccionado?.flyer_web}")`,backgroundSize: 'cover',backgroundPosition: 'center' }}
                        >
                            <Grid item xs={12}>
                                {!enSeleccion && <Card> 
                                <CardHeader
                                    title={eventoSeleccionado?.titulo}
                                    subheader={eventoSeleccionado.subtitulo}
                                    style={{borderBottom:'1px solid gray'}}
                                    titleTypographyProps={{ align: 'center', variant: 'h4' }}
                                    subheaderTypographyProps={{ align: 'center', variant: 'h6', color: 'textSecondary' }}
                                />
                                <Typography variant="subtitle2" style={{margin:'20px'}} align="center">
                                    {eventoSeleccionado.detalles} 
                                </Typography>
                                <CardMedia
                                    component="img"
                                    style={{width:"940px",left:"470px",marginRight:'auto',marginLeft:'auto'}}
                                    image={eventoSeleccionado?.flyer_web}
                                    alt={eventoSeleccionado?.titulo}
                                />
                                <CardContent>
                                
                                    {eventoSeleccionado.texto1 && <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                                    {eventoSeleccionado.texto1}
                                    </Typography>}
                                    {eventoSeleccionado.texto2 && <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                                    {eventoSeleccionado.texto2}
                                    </Typography>}
                                    {eventoSeleccionado.texto3 && <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                                    {eventoSeleccionado.texto3}
                                    </Typography>}
                                    {eventoSeleccionado.texto4 && <Typography variant="body1" sx3={{ padding: '16px', fontSize: '1.5rem' }} align="left">
                                    {eventoSeleccionado.texto4}
                                    </Typography>}
                                </CardContent>
                                </Card>}
                                <Precios evento={eventoSeleccionado} id_evento={idEvento} setEnSeleccion={setEnSeleccion}/>
                            </Grid>
                        </Grid>
                        {/* <NuevaInscripcionNew id_evento={idEvento} eventoSeleccionado={eventoSeleccionado}/> */}
                        {/* <NuevaInscripcion id_evento={idEvento} eventoSeleccionado={eventoSeleccionado}/> */}
                        <Box
                        id="payment-bottom"
                        component="footer"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection:'column',
                            backgroundColor:'rgb(232, 240, 254)',
                            height: '50px',
                            padding:'10px',
                            textAlign:'center',
                            mt: 'auto', // ensures it sticks to the bottom
                        }}
                        >
                            <Typography variant="body1"> {`${eventoSeleccionado.titulo} | ${eventoSeleccionado.detalles}`} </Typography>
                            {eventoSeleccionado?.id_preguntas_frecuentes && <Button id="evento-inicio" onClick={()=>setAbrirPreguntas(true)} variant="text" startIcon={<QuestionMarkIcon />}>
                                Preguntas frecuentes
                            </Button>}
                        </Box>
                    </>}
                </Box>
    </Dialogos> 
    <Box sx={{display:'flex',flexDirection:'column',padding:'20px'}}>
    <Typography variant="h4" align="left" style={{margin:'1.5rem'}}>
                      Próximos eventos:
    </Typography>
            <div style={{minHeight:'350px',
                        marginTop:'30px',
                        display:'flex',
                        flexDirection:'wrap',
                        flexWrap:'wrap',
                        gap:'30px',
                        justifyContent:'center',
                        padding:'10px'}}>            
           {eventos.filter((i)=>i?.activo || esModoTest).map((evento,index)=>{
            return  <Card variant='outlined' style={{width:'350px',background:'#c3c3cf'}}>
            <img src={evento?.foto_mini} width={"350"} height={"175"} alt="evento" />

            <CardHeader title={evento.titulo}
                        titleTypographyProps={{
                            variant: "h6",
                            align: "center"
                            ,color:'primary'
                            }}
            />
            <Typography variant="subtitle2">{evento.detalles}</Typography>
            <CardActions style={{justifyContent:'center'}}>
               <Button onClick={()=>setIdEvento(evento.id_evento)} size="small"  variant="outlined" color='primary'>Inscríbase aquí</Button>
            </CardActions>
            <CardContent>
                <Typography style={{ margin:'10px'}} color="text.secondary">{evento.descripcion}</Typography>
            </CardContent>
        </Card>
        })}
        </div>
        </Box>
</div>
    
}